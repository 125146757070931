// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CWHoad5uWa4dY4XAYdT7{margin:auto;width:50%;padding:2rem;text-align:center}", "",{"version":3,"sources":["webpack://./frontend-react/components/DataProvider.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,SAAA,CACA,YAAA,CACA,iBAAA","sourcesContent":[".data-provider{\n  margin: auto;\n  width: 50%;\n  padding: 2rem;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"data-provider": "CWHoad5uWa4dY4XAYdT7"
};
export default ___CSS_LOADER_EXPORT___;
