import React, { useState } from "react";
import UserDataItem from "../components/items/UserDataItem";
import { useAppSelector } from '../redux/hooks';
import UploadFile from "../components/inputs/UploadFile";
import useDebug from "../hooks/useDebug";
import PageWrapper from "../layouts/Main";
var debug = useDebug('pages:Home');
var Home = function () {
    var _a = useState(null), url = _a[0], setUrl = _a[1];
    var userData = useAppSelector(function (state) { return state.userData.value; }) || [];
    var username = useAppSelector(function (state) { return state.username.value; });
    debug('userData', userData);
    return (React.createElement(PageWrapper, { nav: {
            leftLink: 'home',
            username: username
        } },
        React.createElement("div", { className: ' d-flex justify-content-center min-h-100' },
            React.createElement("div", { className: 'container' },
                React.createElement("section", { className: 'my-5' },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-xl" },
                            React.createElement(UploadFile, null)),
                        React.createElement("div", { className: "col-xl" },
                            React.createElement("label", { className: "form-label" }, "Package leaflets"),
                            React.createElement("small", { className: 'text-muted' },
                                React.createElement("p", { className: 'ms-1 mb-1' }, 'List with uploaded medical package leaflets. Please click on an entry to ' +
                                    'page where FASS package leaflet xml can be created and downloaded')),
                            userData.map(function (item) {
                                return React.createElement(UserDataItem, { key: item.pdf.id, item: item, onClick: setUrl });
                            }))))))));
};
export default Home;
