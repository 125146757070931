/**
 *Created by Mikael Lindahl on 2022-05-10
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import url from "../../constants/url";
import Utils from "../../lib/utils";
import { updateUserDataBoxes } from "../../redux/slices/userDataSlice";
import useDebug from "../../hooks/useDebug";
import { useAppDispatch, useBoxSelector } from "../../redux/hooks";
import theme from "../../constants/theme";
var debug = useDebug('components:ButtonAdd');
var ButtonAdd = function (props) {
    var dispatch = useAppDispatch();
    var boxes = useBoxSelector(props.pdfId, props.pageIndex);
    var color;
    var name;
    switch (props.type) {
        case "figure":
            color = theme.COLOR.FIGURE;
            name = 'Add figure';
            break;
        case "table":
            color = theme.COLOR.TABLE;
            name = 'Add table';
    }
    return (React.createElement("button", { className: 'btn btn-secondary', onClick: function () {
            var box = {
                x0: props.page.width / 2 - 80,
                y0: props.page.height - 120,
                width: 160,
                height: 100,
                pdf_id: props.pdfId,
                page_id: props.page.id,
                type: props.type
            };
            var add = Utils.getAlternativeBoxCoordinates(box.x0, box.y0, box.width, box.height, props.pageHeight);
            box = __assign(__assign({}, box), add);
            fetch(url.BOX_ANNOTATE, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(box)
            })
                .then(function (res) { return res.json(); })
                .then(function (_a) {
                var box = _a.box;
                box.focus = true;
                if (boxes.find(function (b) { return b.id == box.id; })) {
                    throw 'box already exist!!!';
                }
                dispatch(updateUserDataBoxes({
                    pdfId: props.pdfId,
                    pageIndex: props.pageIndex,
                    boxes: __spreadArray(__spreadArray([], boxes, true), [box], false)
                }));
            })
                .catch(function (e) { return console.error(e); });
        } }, name));
};
export default ButtonAdd;
