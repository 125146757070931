/**
 *Created by Mikael Lindahl on 2022-05-04
 */
import React, { useState } from 'react';
import url from "../../constants/url";
import { updateUserDataItem } from "../../redux/slices/userDataSlice";
import { useAppDispatch } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
var XmlItem = function (props) {
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState(null), error = _b[0], setError = _b[1];
    var _c = useState(false), success = _c[0], setSuccess = _c[1];
    var onClick = function () {
        setLoading(true);
        setSuccess(false);
        fetch(url.XML + '/' + props.id, {
            method: 'PUT',
        })
            .then(function (response) { return response.json(); })
            .then(function (result) {
            if (result.error) {
                throw result.error;
            }
            setError(null);
            setSuccess(true);
            setLoading(false);
            dispatch(updateUserDataItem(result.user_data_item));
        })
            .catch(function (error) {
            setSuccess(false);
            setLoading(false);
            setError(error);
            console.error('Error:', error);
        });
    };
    return (React.createElement("div", { className: "form-group mt-5" },
        React.createElement("label", { className: "form-label" }, "XML file"),
        React.createElement("div", { className: 'mb-3' },
            React.createElement("button", { className: 'btn btn-secondary', onClick: function () { return navigate('/app/edit/review/' + props.id + '/0'); } }, 'Review')),
        React.createElement("div", null,
            React.createElement("button", { disabled: props.disabled, className: 'btn btn-primary', onClick: onClick },
                !loading && (props.url_xml ? 'Update xml' : 'Create xml'),
                loading && React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "spinner-border spinner-border-sm me-2", role: "status", "aria-hidden": "true" }),
                    "Loading ..."))),
        props.url_xml && React.createElement("div", { className: 'mt-2' },
            React.createElement("a", { href: props.url_xml, download: props.name_download_xml }, "Download xml")),
        props.url_images && React.createElement("div", { className: 'mt-2' },
            React.createElement("a", { href: props.url_images, download: props.name_download_images }, "Download images")),
        success && React.createElement("p", { className: 'text-success mt-1' },
            React.createElement("small", null, props.url_xml ? 'Xml updated' : 'Xml created!')),
        error && React.createElement("p", { className: 'text-danger mt-1' },
            React.createElement("small", null, props.url_xml ? 'Xml update failed' : 'Xml create failed'))));
};
export default XmlItem;
