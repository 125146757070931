import React, { useState, useEffect } from "react";
import styles from "./DataProvider.module.scss";
import { useAppDispatch } from '../redux/hooks';
import { setUserData } from '../redux/slices/userDataSlice';
import { setUsernameData } from '../redux/slices/usernameSlice';
import useDebug from "../hooks/useDebug";
var debug = useDebug('components:DataProvider');
/**
 *
 * Wrapper for fetching data on page load
 *
 */
var DataProvider = function (props) {
    var _a = useState(false), loaded = _a[0], setLoaded = _a[1];
    var _b = useState('Loading ...'), placeholder = _b[0], setPlaceholder = _b[1];
    var dispatch = useAppDispatch();
    useEffect(function () {
        fetch(props.endpoint)
            .then(function (res) {
            console.log(res);
            return res;
        })
            .then(function (res) { return res.json(); })
            .then(function (data) {
            dispatch(setUserData(data.user_data));
            dispatch(setUsernameData(data.username));
            setLoaded(true);
        })
            .catch(function (error) {
            setPlaceholder("Something went wrong");
            console.log(error);
        });
    });
    return loaded ? props.render() :
        React.createElement("div", { className: styles['data-provider'] },
            React.createElement("p", null, placeholder));
};
export default DataProvider;
