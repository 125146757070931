/**
 *Created by Mikael Lindahl on 2022-03-29
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useContext, useRef, useCallback } from 'react';
import Resizer from "./Resizer";
import useDebug from "../../hooks/useDebug";
import { CanvasContext } from "./Annotate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import url from "../../constants/url";
import { updateUserDataBoxes } from "../../redux/slices/userDataSlice";
import { useAppDispatch, useBoxSelector } from "../../redux/hooks";
import useUpdateBox from "../../hooks/useUpdateBox";
import Utils from "../../lib/utils";
var debug = useDebug('components:annotate:Box');
export default function Box(props) {
    var dispatch = useAppDispatch();
    var boxes = useBoxSelector(props.pdfId, props.pageIndex);
    var canvasRef = useContext(CanvasContext);
    var _a = useState(false), changing = _a[0], setChanging = _a[1];
    var _b = useState(props.box.focused || false), focused = _b[0], setFocused = _b[1];
    // Original position independent of any changing.  Updated when done changing.
    var _c = useState(props.box), box = _c[0], setBox = _c[1];
    var onUpdateBox = useUpdateBox(props.pdfId, props.pageIndex);
    // The distance the mouse has moved since `mousedown`.
    var _d = useState({ x0: 0, y0: 0 }), delta = _d[0], setDelta = _d[1];
    // Store startDragPos in a `ref` so handlers always have the latest value.
    var startDragPos = useRef({ x0: 0, y0: 0 });
    // Need box ref for values to propagate to handleMouseUp listener
    var boxRef = useRef(box);
    var x0 = box.x0 + delta.x0;
    var y0 = box.y0 + delta.y0;
    boxRef.current = __assign(__assign(__assign({}, box), { x0: x0, y0: y0 }), Utils.getAlternativeBoxCoordinates(x0, y0, box.width, box.height, props.pageHeight));
    // `useCallback` is needed because `removeEventListener`` requires the handler
    // to be the same as `addEventListener`.  Without `useCallback` React will
    // create a new handler each render.
    var handleParentMouseMove = useCallback(function (e) {
        setDelta({
            x0: e.clientX - startDragPos.current.x0,
            y0: -(e.clientY - startDragPos.current.y0),
        });
    }, []);
    var handleMouseDown = function (e) {
        if (e.nativeEvent.button === 0) { // Only left click
            startDragPos.current = { x0: e.clientX, y0: e.clientY };
            canvasRef.current.addEventListener('mousemove', handleParentMouseMove);
            canvasRef.current.addEventListener('mouseup', handleMouseUp);
            setChanging(true);
            if (!focused) {
                canvasRef.current.addEventListener('mousedown', handleFocus);
                document.addEventListener('keypress', handleDeleteKey);
                setFocused(true);
            }
        }
    };
    var handleMouseUp = useCallback(function (e) {
        if (e.button === 0) { // Only left click
            canvasRef.current.removeEventListener('mousemove', handleParentMouseMove);
            canvasRef.current.removeEventListener('mouseup', handleMouseUp);
            if (startDragPos.current.x0 != e.clientX && startDragPos.current.y0 != e.clientY) {
                onUpdateBox(boxRef.current, setBox);
            }
            setChanging(false);
            setBox(boxRef.current);
            startDragPos.current = { x0: 0, y0: 0 };
            setDelta({ x0: 0, y0: 0 });
        }
    }, []);
    var handleFocus = function (e) {
        var target = e.target;
        if (!(target.id == String(box.id)
            || target.querySelector('circle')
            || e.target instanceof SVGPathElement
            || e.target instanceof SVGCircleElement)) {
            // debug('handleFocus', changing)
            setFocused(false);
            canvasRef.current.removeEventListener('mousedown', handleFocus);
            document.removeEventListener('keypress', handleDeleteKey);
        }
    };
    var handleDelete = function () {
        debug('handleDelete');
        fetch(url.BOX_ANNOTATE + '/' + box.id, {
            method: 'DELETE',
        })
            .then(function (res) { return res.json(); })
            .then(function (_a) {
            var message = _a.message;
            debug('message', message);
            if (message == 'deleted') {
                var remaining = boxes.filter(function (b) { return b.id != box.id; });
                dispatch(updateUserDataBoxes({
                    pdfId: props.pdfId,
                    pageIndex: props.pageIndex,
                    boxes: remaining
                }));
            }
        })
            .catch(function (e) { return console.error(e); });
    };
    var handleDeleteKey = function (e) {
        if (e.code == 'Delete') {
            debug('handleDeleteKey pressed');
            handleDelete();
        }
    };
    var color = 'black';
    var strokeDasharray = changing || !focused ? 'none' : '4 2';
    var lineCutOff = 4;
    var boxSmall = (box.width < 25 && box.height < 25);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: {
                position: "absolute",
                left: x0,
                bottom: y0 + box.height,
                fontSize: 8,
                backgroundColor: 'white'
            } }, "".concat(box.type, " - ").concat(box.position_in_text)),
        React.createElement("div", { style: {
                position: "absolute",
                left: x0,
                bottom: y0,
                width: box.width,
                height: box.height
            } },
            React.createElement("div", { style: {
                    position: "relative",
                    width: box.width,
                    height: box.height
                } },
                React.createElement("svg", { onMouseDown: handleMouseDown, id: String(box.id), cursor: 'move', style: {
                        position: "absolute",
                        width: box.width,
                        height: box.height
                    } },
                    React.createElement("rect", { width: '100%', height: '100%', fill: 'none', strokeWidth: 2, strokeDasharray: strokeDasharray, stroke: 'black' }),
                    !focused && !boxSmall && React.createElement(React.Fragment, null,
                        React.createElement("line", { x1: lineCutOff, y1: box.height - lineCutOff, x2: box.width - lineCutOff, y2: lineCutOff, stroke: color, strokeDasharray: '4 2' }),
                        React.createElement("line", { x1: lineCutOff, y1: lineCutOff, x2: box.width - lineCutOff, y2: box.height - lineCutOff, stroke: color, strokeDasharray: '4 2' }))),
                focused && React.createElement(React.Fragment, null,
                    React.createElement("div", { style: {
                            position: 'absolute',
                            right: -25,
                            top: box.height / 2 - 12,
                        }, onClick: function () {
                            debug('delete!!!!');
                            handleDelete();
                        } },
                        React.createElement(FontAwesomeIcon, { width: 10, className: 'me-2', icon: faTrash })),
                    React.createElement(Resizer, { pdfId: props.pdfId, pageIndex: props.pageIndex, pageHeight: props.pageHeight, position: 'upper-left', setChanging: setChanging, setBox: setBox, box: box }),
                    !boxSmall && React.createElement(React.Fragment, null,
                        React.createElement(Resizer, { pdfId: props.pdfId, pageIndex: props.pageIndex, pageHeight: props.pageHeight, position: 'upper-right', setChanging: setChanging, setBox: setBox, box: box }),
                        React.createElement(Resizer, { pdfId: props.pdfId, pageIndex: props.pageIndex, pageHeight: props.pageHeight, position: 'bottom-left', setChanging: setChanging, setBox: setBox, box: box }),
                        React.createElement(Resizer, { pdfId: props.pdfId, pageIndex: props.pageIndex, pageHeight: props.pageHeight, position: 'bottom-right', setChanging: setChanging, setBox: setBox, box: box })))))));
}
