/**
 *Created by Mikael Lindahl on 2022-05-10
 */
"use strict";
export function clone(d) {
    return JSON.parse(JSON.stringify(d));
}
export function getAlternativeBoxCoordinates(x0, y0, width, height, pageHeight) {
    return {
        x1: x0 + width,
        y1: y0 + height,
        top: pageHeight - y0 - height,
        bottom: pageHeight - y0 // From top of page to bottom of box
    };
}
export default {
    clone: clone,
    getAlternativeBoxCoordinates: getAlternativeBoxCoordinates
};
