/**
 *Created by Mikael Lindahl on 2022-05-02
 */
import React, { useState } from 'react';
import { useAppDispatch } from "../../redux/hooks";
import url from "../../constants/url";
import { deleteUserData } from "../../redux/slices/userDataSlice";
import useDebug from "../../hooks/useDebug";
import styles from "./UserDataItemDelete.module.scss";
var debug = useDebug('components:items:UserDataItemDelete');
var UserDataItemDelete = function (props) {
    var dispatch = useAppDispatch();
    var _a = useState(null), error = _a[0], setError = _a[1];
    var onDelete = function () {
        debug('url.FILE_UPLOAD + \'/\' + props.id', url.FILE_UPLOAD + '/' + props.id);
        fetch(url.FILE_UPLOAD + '/' + props.id, { method: 'DELETE' })
            .then(function (response) { return response.json(); })
            .then(function (result) {
            if (result.error) {
                throw result.error;
            }
            dispatch(deleteUserData(props.id));
        })
            .catch(function (error) {
            setError(error);
            console.error('Error:', error);
        });
    };
    return React.createElement("div", { className: 'd-flex mb-3' },
        React.createElement("div", { className: styles.container },
            React.createElement("p", { className: 'm-1' },
                React.createElement("small", null, "Are you sure you want to delete this entry?")),
            React.createElement("div", { className: 'm-1' },
                React.createElement("button", { className: 'btn btn-primary me-1', onClick: function () {
                        onDelete();
                        // setEditItemId(-1)
                    } }, "Delete"),
                React.createElement("button", { className: 'btn btn-danger', onClick: function () {
                        props.onClose();
                    } }, "Close")),
            error && React.createElement("p", { className: 'text-danger' }, "File deletion failed!")));
};
export default UserDataItemDelete;
