// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X_NKvvVtuCkzmwSQ8Q9S{background-color:#d3d3d3}.AjBwTzFpvofNcHrFibNv{outline:#000 solid 1px;margin:1px;display:inline-flex}", "",{"version":3,"sources":["webpack://./frontend-react/components/annotate/Annotate.module.scss"],"names":[],"mappings":"AACA,sBACE,wBAAA,CAKF,sBACE,sBAAA,CACA,UAAA,CACA,mBAAA","sourcesContent":["\n.annotate {\n  background-color: lightgray;\n  //height: 100%;\n}\n\n\n.canvas{\n  outline: black solid 1px;\n  margin: 1px;\n  display: inline-flex\n  //flex: fit-content;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"annotate": "X_NKvvVtuCkzmwSQ8Q9S",
	"canvas": "AjBwTzFpvofNcHrFibNv"
};
export default ___CSS_LOADER_EXPORT___;
