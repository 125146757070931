/**
 *Created by Mikael Lindahl on 2019-09-16
 */
"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import Footer from './components/Footer';
import Nav from './components/Nav';
import Banner from "./components/Banner";
import useDebug from "../hooks/useDebug";
var debug = useDebug('layouts:Main');
var Main = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Nav, __assign({}, props.nav)),
        props.banner && React.createElement(Banner, { label: props.banner.label }),
        props.children,
        React.createElement(Footer, null)));
};
export default Main;
