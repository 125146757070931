/**
 *Created by Mikael Lindahl on 2022-04-29
 */
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
// Define the initial state using that type
var initialState = { value: [] };
export var userDataSlice = createSlice({
    name: 'userData',
    initialState: initialState,
    reducers: {
        deleteUserData: function (state, action) {
            var values = state.value.filter(function (e) { return e.pdf.id != action.payload; });
            state.value = values;
        },
        setUserData: function (state, action) {
            state.value = action.payload;
        },
        updateUserDataItem: function (state, action) {
            var index = state.value.findIndex(function (e) { return e.pdf.id == action.payload.pdf.id; });
            var value = __spreadArray([], state.value, true);
            if (index > -1) {
                value[index] = action.payload;
            }
            else {
                value = __spreadArray(__spreadArray([], value, true), [action.payload], false);
            }
            state.value = value;
        },
        updateUserData: function (state, action) {
            var value = __spreadArray([], state.value, true);
            var _loop_1 = function (user_data) {
                var index = state.value.findIndex(function (e) { return e.pdf.id == user_data.pdf.id; });
                if (index > -1) {
                    value[index] = user_data;
                }
                else {
                    value = __spreadArray(__spreadArray([], value, true), [user_data], false);
                }
            };
            for (var _i = 0, _a = action.payload; _i < _a.length; _i++) {
                var user_data = _a[_i];
                _loop_1(user_data);
            }
            state.value = value;
        },
        updateUserDataBoxes: function (state, action) {
            var index = state.value.findIndex(function (e) { return e.pdf.id == Number(action.payload.pdfId); });
            var value = __spreadArray([], state.value, true);
            if (index > -1) {
                value[index].pages[Number(action.payload.pageIndex)].boxes = action.payload.boxes;
            }
            else {
                throw 'Trying to update user data boxes on item that do not exist';
            }
            state.value = value;
        }
    },
});
// Action creators are generated for each case reducer function
export var deleteUserData = (_a = userDataSlice.actions, _a.deleteUserData), setUserData = _a.setUserData, updateUserDataItem = _a.updateUserDataItem, updateUserData = _a.updateUserData, updateUserDataBoxes = _a.updateUserDataBoxes;
export default userDataSlice.reducer;
