/**
 *Created by Mikael Lindahl on 2019-09-16
 */
"use strict";
import React from "react";
var Footer = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("footer", { key: 'footer', className: 'bg-dark text-center text-white', role: "contentinfo" },
            React.createElement("div", { className: "container p-5" },
                React.createElement("div", { key: 'copywrite', className: "small-12 text-center" },
                    React.createElement("p", { className: "source-org copyright" }))),
            React.createElement("div", { className: "text-center p-3", style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } },
                '© 2022 Copyright: ',
                React.createElement("a", { className: "text-light", href: "https://isolve.se/" }, 'isolve.se')))));
};
export default Footer;
