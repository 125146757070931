/**
 *Created by Mikael Lindahl on 2022-05-05
 */
import React from 'react';
var PdfItem = function (props) {
    return (React.createElement("div", { className: "form-group mt-5" },
        React.createElement("label", { className: "form-label" }, props.label),
        React.createElement("div", null,
            React.createElement("small", { className: 'text-muted' }, props.help)),
        React.createElement("div", { className: 'mt-2' },
            React.createElement("a", { href: props.url, download: props.name_download }, "Download"))));
};
export default PdfItem;
