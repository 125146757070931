/**
 *Created by Mikael Lindahl on 2022-05-03
 */
import React, { useState } from 'react';
import url from "../../constants/url";
import { updateUserDataItem } from "../../redux/slices/userDataSlice";
import { useAppDispatch } from "../../redux/hooks";
import useDebug from "../../hooks/useDebug";
var debug = useDebug('components:inputs:InputTradeName');
var InputTradeName = function (props) {
    var dispatch = useAppDispatch();
    var _a = useState(false), edit = _a[0], setEdit = _a[1];
    var _b = useState(props.value), value = _b[0], setValue = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var _d = useState(false), success = _d[0], setSuccess = _d[1];
    var onSave = function () {
        setError(null);
        setSuccess(false);
        fetch(url.TRADE_NAME + '/' + props.id, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'trade_name': value })
        })
            .then(function (response) { return response.json(); })
            .then(function (result) {
            debug(result);
            if (result.error) {
                throw result;
                if (result.error == 'invalid trade name') {
                }
                else {
                    throw result.error;
                }
            }
            else {
                setError(null);
                setSuccess(true);
                dispatch(updateUserDataItem(result.user_data_item));
            }
        })
            .catch(function (error) {
            if (error.error == 'invalid trade name') {
                setError(error);
            }
            else {
                setError({ message: 'Saving trade name failed', error: error });
            }
            setSuccess(false);
            setError(error);
            console.error('Error:', error);
        });
    };
    return (React.createElement("div", { className: 'mt-5' },
        React.createElement("div", { className: 'd-flex' },
            React.createElement("div", null,
                React.createElement("div", { className: "form-group" },
                    React.createElement("label", { className: "form-label", htmlFor: "form12" }, "Trade name"),
                    React.createElement("div", null,
                        React.createElement("small", { className: 'text-muted' }, 'Please add package leaflet trade name. ' +
                            'Should be spelled exactly as in leaflet pdf. E.g. "fungoral" has trade ' +
                            'name "Fungoral"')),
                    React.createElement("input", { type: "text", id: "form12", className: "form-control", onClick: function () { return setEdit(true); }, onChange: function (e) { return setValue(e.target.value); }, value: value })),
                edit && value != '' && React.createElement("div", { className: 'mt-3' },
                    React.createElement("button", { className: 'btn btn-primary me-2 ', onClick: onSave }, "Save"),
                    React.createElement("button", { className: 'btn btn-danger', onClick: function () {
                            setEdit(false);
                        } }, "Cancel")),
                success && React.createElement("p", { className: 'text-success mt-1' },
                    React.createElement("small", null, 'Trade name saved!')),
                error && React.createElement("p", { className: 'text-danger mt-1' },
                    React.createElement("small", null, error.message))),
            React.createElement("div", { className: 'p-3' },
                React.createElement("img", { height: '150px', src: '/static/images/trade_name_example.png' })))));
};
export default InputTradeName;
