/**
 *Created by Mikael Lindahl on 2019-12-10
 */
import React, { useState, useRef } from 'react';
import styles from "./InputFile.module.scss";
var InputFile = function (props) {
    var inputRef = useRef(null);
    var _a = useState(null), selectedFile = _a[0], setSelectedFile = _a[1];
    var _b = useState(false), isSelected = _b[0], setIsSelected = _b[1];
    var onChangeFile = function (event) {
        var file = event.target.files[0];
        props.onChange(file);
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
    };
    return (React.createElement("div", { className: "form-group " + styles['input-file'] },
        React.createElement("label", { className: "form-label" }, "File input"),
        React.createElement("small", { className: 'text-muted' },
            React.createElement("p", { className: 'ms-1 mb-1' }, "Please select a medicine package leaflet pdf to upload")),
        React.createElement("input", { disabled: props.disabled, className: "form-control", type: "file", name: "file", onChange: onChangeFile, ref: inputRef }),
        React.createElement("div", null,
            React.createElement("button", { disabled: props.disabled, className: 'btn btn-secondary', onClick: function () { return inputRef.current.click(); } }, "Choose file")),
        isSelected ? (React.createElement("div", { className: 'mt-2' },
            React.createElement("small", { className: 'text-muted' },
                React.createElement("p", { className: 'ms-1 mb-1' },
                    "Filename: ",
                    selectedFile.name),
                React.createElement("p", { className: 'ms-1 mb-1' },
                    "Filetype: ",
                    selectedFile.type),
                React.createElement("p", { className: 'ms-1 mb-1' },
                    "Size in bytes: ",
                    selectedFile.size),
                React.createElement("p", { className: 'ms-1 mb-1' },
                    "lastModifiedDate:",
                    ' ',
                    (new Date(selectedFile.lastModified)).toLocaleDateString())))) : (React.createElement("div", { className: 'mt-2' }))));
};
export default InputFile;
