/**
 *Created by Mikael Lindahl on 2022-04-29
 */
import { configureStore } from '@reduxjs/toolkit';
import userDataReducer from "./slices/userDataSlice";
import usernameReducer from "./slices/usernameSlice";
var store = configureStore({
    reducer: {
        userData: userDataReducer,
        username: usernameReducer
    },
});
export default store;
