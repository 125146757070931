// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".opGISFtyCFEgwQr0LxDn{min-height:40px}.U8NjgSOXRy0ySDy2w7q0{background-color:#6c757d;border-radius:10px;color:#fff;cursor:pointer}.TNydtIF9XE_qU7fk00Yz{font-size:xx-small;color:#c7c7c7;height:12px}.T5X_KgyZURK4OafeYGlX{font-size:x-small}.JETQPmuSSDMwylAyuFE1{white-space:nowrap;font-size:x-large;text-transform:uppercase;padding:.5rem .5rem .5rem .75rem}", "",{"version":3,"sources":["webpack://./frontend-react/components/items/UserDataItem.module.scss"],"names":[],"mappings":"AAIA,sBAEE,eAAA,CAIF,sBAEE,wBAAA,CACA,kBAAA,CACA,UAAA,CACA,cAAA,CAIF,sBACE,kBAAA,CACA,aAAA,CACA,WAAA,CAGF,sBACE,iBAAA,CAIF,sBAEE,kBAAA,CACA,iBAAA,CACA,wBAAA,CACA,gCAAA","sourcesContent":["/**\n *Created by Mikael Lindahl on 2022-05-02\n */\n\n.button{\n\n  min-height: 40px;\n\n}\n\n.card{\n\n  background-color: #6c757d;\n  border-radius:10px;\n  color:white;\n  cursor: pointer;\n\n}\n\n.date{\n  font-size: xx-small;\n  color: #c7c7c7;\n  height:12px;\n}\n\n.text-xs{\n  font-size: x-small;\n\n}\n\n.title{\n\n  white-space: nowrap;\n  font-size: x-large;\n  text-transform: uppercase;\n  padding: 0.5rem 0.5rem 0.5rem .75rem;\n}\n\n.user-data-item{\n\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "opGISFtyCFEgwQr0LxDn",
	"card": "U8NjgSOXRy0ySDy2w7q0",
	"date": "TNydtIF9XE_qU7fk00Yz",
	"text-xs": "T5X_KgyZURK4OafeYGlX",
	"title": "JETQPmuSSDMwylAyuFE1"
};
export default ___CSS_LOADER_EXPORT___;
