// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UxoyyLfJIwNM8NjRF624 input[type=file]{display:none;padding:0px;background-color:#12263f}", "",{"version":3,"sources":["webpack://./frontend-react/components/inputs/InputFile.module.scss"],"names":[],"mappings":"AACE,uCAEE,YAAA,CACA,WAAA,CACA,wBAAA","sourcesContent":[".input-file{\n  input[type=\"file\"]{\n\n    display: none;\n    padding: 0px;\n    background-color: #12263f;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-file": "UxoyyLfJIwNM8NjRF624"
};
export default ___CSS_LOADER_EXPORT___;
