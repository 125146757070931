/**
 *Created by Mikael Lindahl on 2022-04-29
 */
import { useDispatch, useSelector } from 'react-redux';
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export var useAppDispatch = function () { return useDispatch(); };
export var useAppSelector = useSelector;
export var useBoxSelector = function (pdfId, pageIndex) {
    return useSelector(function (state) {
        var userData = state.userData.value.find(function (x) { return x.pdf.id == Number(pdfId); });
        return userData.pages[Number(pageIndex)].boxes;
    });
};
