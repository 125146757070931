/**
 *Created by Mikael Lindahl on 2019-09-16
 */
"use strict";
import React from "react";
import useDebug from "../../hooks/useDebug";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
var debug = useDebug('layouts:components:Nav');
import { Link } from "react-router-dom";
var TheNav = function (props) {
    return (
    // Navbar
    React.createElement("nav", { className: "navbar navbar-expand-lg navbar-dark bg-dark" },
        React.createElement("div", { className: "container-fluid" },
            props.leftLink == 'back' &&
                React.createElement(Link, { className: "navbar-brand mb-0 h1 text-uppercase h1", to: props.leftLinkHref || '/app' },
                    React.createElement(FontAwesomeIcon, { className: 'me-2', icon: faArrowLeft }),
                    "Back"),
            props.leftLink == 'home' &&
                React.createElement(Link, { className: "navbar-brand mb-0 h1 text-uppercase h1", to: '/app' }, "Fass ai xml"),
            React.createElement("div", { className: "d-flex align-items-center navbar-nav" },
                React.createElement("div", { className: "nav-item dropdown" },
                    React.createElement("a", { className: "nav-link dropdown-toggle", href: "#", role: "button", id: "dropdownMenuLink", "data-bs-toggle": "dropdown", "aria-expanded": "false" }, props.username),
                    React.createElement("ul", { className: "dropdown-menu dropdown-menu-end", "aria-labelledby": "dropdownMenuLink" },
                        React.createElement("li", null,
                            React.createElement(Link, { className: "dropdown-item", to: "/app" }, "Dashboard")),
                        React.createElement("li", null,
                            React.createElement("a", { className: "dropdown-item", href: "/accounts/logout" }, "Logout")),
                        React.createElement("li", null,
                            React.createElement("hr", { className: "dropdown-divider" })),
                        React.createElement("li", null,
                            React.createElement("a", { className: "dropdown-item", href: "#" }, "About")),
                        React.createElement("li", null,
                            React.createElement("a", { className: "dropdown-item", href: "#" }, "Help")))))))
    // Navbar
    );
};
export default TheNav;
