/**
 *Created by Mikael Lindahl on 2022-05-03
 */
import React from 'react';
import PageWrapper from "../layouts/Main";
import { useParams } from "react-router-dom";
import useDebug from "../hooks/useDebug";
import { useAppSelector } from "../redux/hooks";
import Annotate from "../components/annotate/Annotate";
var debug = useDebug('pages:EditReview');
var Edit = function () {
    var pdfId = useParams().pdfId;
    var userData = useAppSelector(function (state) { return state.userData.value.find(function (v) { return v.pdf.id == parseInt(pdfId); }); });
    var username = useAppSelector(function (state) { return state.username.value; });
    return (React.createElement(PageWrapper, { banner: {
            label: userData.pdf.trade_name || userData.pdf.name
        }, nav: {
            leftLink: 'back',
            leftLinkHref: '/app/edit/' + pdfId,
            username: username
        } },
        React.createElement(Annotate, { pages: userData.pages })));
};
export default Edit;
