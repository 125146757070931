/**
 *Created by Mikael Lindahl on 2022-05-08
 */
import React, { useRef } from 'react';
import styles from "./Annotate.module.scss";
import useDebug from "../../hooks/useDebug";
import ButtonsNavigation from "./ButtonsNavigation";
import Box from "./Box";
import ButtonAdd from "./ButtonAdd";
import { useBoxSelector } from "../../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
var debug = useDebug('components:Annotate');
export var CanvasContext = React.createContext(null);
var Annotate = function (props) {
    var canvasRef = useRef();
    var navigate = useNavigate();
    var _a = useParams(), pdfId = _a.pdfId, pageIndex = _a.pageIndex;
    var boxes = useBoxSelector(pdfId, pageIndex);
    var pageHeight = props.pages[Number(pageIndex)].height;
    return (React.createElement(CanvasContext.Provider, { value: canvasRef },
        React.createElement("div", { className: styles.annotate },
            React.createElement("div", { className: 'container p-5 h-100' },
                React.createElement("div", { className: 'row' },
                    React.createElement("div", { className: 'col align-self-center' },
                        React.createElement(ButtonsNavigation, { position: 'left', index: pageIndex, number_of_pages: props.pages.length, setIndex: function (index) {
                                navigate('/app/edit/review/' + pdfId + '/' + index);
                            } })),
                    React.createElement("div", { className: 'col' },
                        React.createElement("div", { className: 'd-flex mb-2' },
                            React.createElement("div", { className: 'me-3' },
                                React.createElement(ButtonAdd, { pdfId: pdfId, pageIndex: pageIndex, page: props.pages[Number(pageIndex)], pageHeight: pageHeight, type: 'figure' })),
                            React.createElement("div", null,
                                React.createElement(ButtonAdd, { pdfId: pdfId, pageIndex: pageIndex, page: props.pages[Number(pageIndex)], pageHeight: pageHeight, type: 'table' })),
                            React.createElement("div", { className: 'ms-auto pt-3' }, "Page: ".concat(Number(pageIndex) + 1, " (").concat(props.pages.length, ")"))),
                        React.createElement("div", { className: 'd-flex justify-content-center' },
                            React.createElement("div", { className: styles.canvas + ' d-inline-flex' },
                                React.createElement("div", { ref: canvasRef, style: {
                                        position: "relative",
                                        left: "".concat(0, "px"),
                                        top: "".concat(0, "px"),
                                        width: "".concat(props.pages[Number(pageIndex)].width, "px"),
                                        height: "".concat(props.pages[Number(pageIndex)].height, "px"),
                                    } },
                                    React.createElement("img", { src: props.pages[Number(pageIndex)].url, style: {
                                            position: "absolute",
                                            right: 0,
                                            top: 0,
                                            zIndex: 0
                                        } }),
                                    boxes.map(function (box, key) {
                                        return React.createElement(Box, { pdfId: pdfId, pageIndex: pageIndex, pageHeight: pageHeight, key: box.id, box: box });
                                    })))),
                        React.createElement("div", null,
                            React.createElement("button", { className: 'btn btn-primary mt-2', onClick: function () { return navigate('/app/edit/' + pdfId); } }, "Done"))),
                    React.createElement("div", { className: 'col align-self-center' },
                        React.createElement(ButtonsNavigation, { position: 'right', index: pageIndex, number_of_pages: props.pages.length, setIndex: function (index) {
                                navigate('/app/edit/review/' + pdfId + '/' + index);
                            } })))))));
};
export default Annotate;
