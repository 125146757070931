/**
 *Created by Mikael Lindahl on 2022-05-11
 */
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import url from "../constants/url";
import { updateUserDataBoxes } from "../redux/slices/userDataSlice";
import useDebug from "./useDebug";
import { useAppDispatch, useBoxSelector } from "../redux/hooks";
var debug = useDebug('hooks:useUpdateBox');
var useUpdateBox = function (pdfId, pageIndex) {
    // const {pdfId} = useParams()
    var dispatch = useAppDispatch();
    var boxes = useBoxSelector(pdfId, pageIndex);
    var onUpdateBox = function (box, setBox) {
        var payload = {
            x0: box.x0,
            y0: box.y0,
            x1: box.x1,
            y1: box.y1,
            top: box.top,
            bottom: box.bottom,
            width: box.width,
            height: box.height,
        };
        fetch(url.BOX_ANNOTATE + '/' + box.id, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(function (res) { return res.json(); })
            .then(function (_a) {
            var message = _a.message, box = _a.box;
            debug('message box.position_in_text', message, box.position_in_text);
            if (message == 'updated') {
                // let ud = userData.filter(x => x.pdf.id == Number(pdfId))[0]
                //
                // ud = clone(ud)
                // let pageIndex = ud.pages.findIndex(p => p.id == box.page_id)
                var boxIndex = boxes.findIndex(function (b) { return b.id == box.id; });
                // debug('box',boxIndex, box,pdfId, pageIndex,boxes, boxes[boxIndex])
                var update = __spreadArray([], boxes, true);
                update[boxIndex] = box;
                setBox(box);
                dispatch(updateUserDataBoxes({ pdfId: pdfId, pageIndex: pageIndex, boxes: update }));
            }
        })
            .catch(function (e) {
            alert('OPS... saving to db failed. Please check console log message');
            console.error(e);
        });
    };
    return onUpdateBox;
};
export default useUpdateBox;
