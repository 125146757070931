/**
 *Created by Mikael Lindahl on 2022-04-29
 */
import { createSlice } from '@reduxjs/toolkit';
// Define the initial state using that type
var initialState = { value: '' };
export var usernameSlice = createSlice({
    name: 'userData',
    initialState: initialState,
    reducers: {
        setUsernameData: function (state, action) {
            state.value = action.payload;
        }
    },
});
// Action creators are generated for each case reducer function
export var setUsernameData = usernameSlice.actions.setUsernameData;
export default usernameSlice.reducer;
