/**
 *Created by Mikael Lindahl on 2022-05-12
 */
import React from 'react';
import url from "../../constants/url";
var TestDataItem = function (props) {
    return (React.createElement("div", { className: "form-group mt-5" },
        React.createElement("label", { className: "form-label" }, "Test data"),
        React.createElement("div", null,
            React.createElement("small", { className: 'text-muted' }, 'Download test data in a zip file')),
        React.createElement("div", { className: 'mt-2' },
            React.createElement("a", { href: url.TEST_DATA + '/' + props.pdfId, download: props.name_download }, "Download"))));
};
export default TestDataItem;
