/**
 *Created by Mikael Lindahl on 2022-05-03
 */
import React from 'react';
import PageWrapper from "../layouts/Main";
import { useParams } from "react-router-dom";
import XmlItem from "../components/items/XmlItem";
import useDebug from "../hooks/useDebug";
import { useAppSelector } from "../redux/hooks";
import InputTradeName from "../components/inputs/InputTradeName";
import PdfItem from "../components/items/PdfItem";
import TestDataItem from "../components/items/TestDataItem";
var debug = useDebug('pages:Edit');
var Edit = function () {
    var pdfId = useParams().pdfId;
    var userData = useAppSelector(function (state) { return state.userData.value.filter(function (v) { return v.pdf.id == parseInt(pdfId); })[0]; });
    var username = useAppSelector(function (state) { return state.username.value; });
    debug('Edit', userData);
    return (React.createElement(PageWrapper, { banner: {
            label: (userData.pdf.trade_name || userData.pdf.name)
                + (userData.pdf.multiple_package_leaflets
                    ? " (".concat(userData.pdf.package_leaflet_number, ")")
                    : '')
        }, nav: {
            leftLink: 'back',
            username: username
        } },
        React.createElement("div", { className: 'container p-5 h-100' },
            React.createElement(InputTradeName, { id: userData.pdf.id, value: userData.pdf.trade_name || '' }),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col' },
                    React.createElement(PdfItem, { help: 'Download package leaflet pdf. This is trimmed pdf only containing ' +
                            'pages onwards from where the package leaflets begins in the submitted pdf', label: 'Pdf package leaflet', name_download: userData.pdf.name.split('.')[0] + '_package_leaflet.pdf', url: userData.pdf.url })),
                React.createElement("div", { className: 'col' },
                    React.createElement(PdfItem, { help: 'Original submitted pdf', label: 'Pdf original', name_download: userData.pdf.name.split('.')[0] + '_original.pdf', url: userData.pdf.url_original })),
                React.createElement("div", { className: 'col' },
                    React.createElement(XmlItem, { disabled: !userData.pdf.trade_name, id: userData.pdf.id, name_download_xml: userData.pdf.name.split('.')[0] + '.xml', name_download_images: userData.pdf.name.split('.')[0] + '-images.zip', url_xml: userData.pdf.url_xml, url_images: userData.pdf.url_images })),
                React.createElement("div", { className: 'col' },
                    React.createElement(TestDataItem, { pdfId: pdfId, name_download: userData.pdf.name.split('.')[0] + (userData.pdf.multiple_package_leaflets
                            ? "_".concat(userData.pdf.package_leaflet_number)
                            : '') + '_test_data.zip' }))))));
};
export default Edit;
