/**
 *Created by Mikael Lindahl on 2022-04-29
 */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import UserDataItemDelete from "./UserDataItemDelete";
import styles from "./UserDataItem.module.scss";
import { useNavigate } from "react-router-dom";
var UserDataItem = function (props) {
    var navigate = useNavigate();
    var _a = useState(false), remove = _a[0], setRemove = _a[1];
    return (React.createElement("div", null,
        React.createElement("div", { className: 'd-flex p-1' },
            React.createElement("div", { className: 'flex-fill my-1 me-1' },
                React.createElement("div", null,
                    React.createElement("div", { className: styles.card + ' p-2', onClick: function () {
                            setRemove(false);
                            navigate('/app/edit/' + props.item.pdf.id);
                        } },
                        React.createElement("div", { className: 'd-flex' },
                            React.createElement("div", { className: 'd-flex flex-grow-1' },
                                React.createElement("div", { className: styles['title'] + ' align-self-center' }, (props.item.pdf.trade_name || props.item.pdf.name)
                                    + (props.item.pdf.multiple_package_leaflets
                                        ? " (".concat(props.item.pdf.package_leaflet_number, ")")
                                        : '')),
                                React.createElement("div", { className: styles['text-xs'] + ' p-2 align-self-center' }, props.item.pdf.description.map(function (s, i) {
                                    return React.createElement("div", { key: i },
                                        React.createElement("em", null, s));
                                }))),
                            React.createElement("div", { className: 'd-flex align-items-start flex-column p-2 pl-3 w-25' },
                                React.createElement("div", { className: 'align-self-end' },
                                    React.createElement("div", { className: 'align-self-start ' + styles['date'] }, "Created at:"),
                                    React.createElement("div", { className: 'align-self-start ' + styles['date'] }, "".concat(props.item.pdf.created_at.split('T')[0])),
                                    React.createElement("div", { className: 'align-self-start ' + styles['date'] }, "".concat(props.item.pdf.created_at.split('T')[1])))))))),
            React.createElement("div", { className: 'd-flex flex-column' },
                React.createElement("div", { onClick: function () {
                        setRemove(true);
                    } },
                    React.createElement(FontAwesomeIcon, { icon: faTrashAlt })))),
        remove && React.createElement(UserDataItemDelete, { id: props.item.pdf.id, onClose: function () { return setRemove(false); } })));
};
export default UserDataItem;
