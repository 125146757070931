/**
 *Created by Mikael Lindahl on 2022-05-09
 */
import React from 'react';
import useDebug from "../../hooks/useDebug";
var debug = useDebug('components:AnnotateButtonsNavigation');
var ButtonsNavigation = function (props) {
    return (React.createElement("div", { className: 'd-flex justify-content-center' },
        props.position == 'left' && React.createElement("button", { className: 'btn btn-secondary m-3', disabled: props.index == 0, onClick: function () {
                debug('onClickPrevious');
                props.setIndex(Number(props.index) - 1);
            } }, "Previous page"),
        props.position == 'right' && React.createElement("button", { className: 'btn btn-secondary m-3', disabled: props.index == props.number_of_pages - 1, onClick: function () {
                debug('onClickNext');
                props.setIndex(Number(props.index) + 1);
            } }, "Next page")));
};
export default ButtonsNavigation;
