// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RG8QFp3gigCmuObpN34_{float:right;width:180px;margin-left:auto}", "",{"version":3,"sources":["webpack://./frontend-react/components/items/UserDataItemDelete.module.scss"],"names":[],"mappings":"AAIA,sBAEE,WAAA,CACA,WAAA,CACA,gBAAA","sourcesContent":["/**\n *Created by Mikael Lindahl on 2022-05-25\n */\n\n.container{\n\n  float:right;\n  width: 180px;\n  margin-left:auto;\n}\n\n//.buttons{\n//  //float:right;\n//}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "RG8QFp3gigCmuObpN34_"
};
export default ___CSS_LOADER_EXPORT___;
