/**
 *Created by Mikael Lindahl on 2022-05-02
 */
import React, { useState } from 'react';
import InputFile from "./InputFile";
import useFormUpload from "../../hooks/useFormUpload";
import { updateUserData } from "../../redux/slices/userDataSlice";
import { useAppDispatch } from "../../redux/hooks";
var debug = require('debug')('bk-manager:frontend-react:components:inputs:UploadFile');
var UploadFile = function () {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState(false), success = _b[0], setSuccess = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var dispatch = useAppDispatch();
    var _d = useFormUpload({
        path: '/api/file-upload',
        onError: function (error) {
            if (error.error == 'invalid file format') {
                setError(error);
            }
            else {
                setError({ message: 'File uploaded failed!', error: error });
            }
            setLoading(false);
        },
        onSuccess: function (result) {
            dispatch(updateUserData(result.user_data));
            setSuccess(true);
            setLoading(false);
        },
    }), formData = _d.formData, onFormUpload = _d.onFormUpload, setFormData = _d.setFormData;
    return React.createElement("div", { className: 'mb-5' },
        React.createElement(InputFile, { onChange: function (file) {
                var formData = new FormData();
                formData.append('File', file);
                debug('formData', formData);
                setFormData(formData);
            } }),
        React.createElement("button", { disabled: formData == null, className: 'mt-3 btn btn-primary', onClick: function () {
                setError(null);
                setLoading(true);
                setSuccess(false);
                onFormUpload();
            } },
            !loading && "Upload",
            loading && React.createElement(React.Fragment, null,
                React.createElement("span", { className: "spinner-border spinner-border-sm me-2", role: "status", "aria-hidden": "true" }),
                "Uploading...")),
        success && React.createElement("p", { className: 'mt-1' },
            React.createElement("small", { className: 'text-success ms-1' }, "File uploaded!")),
        error && React.createElement("p", { className: 'mt-1' },
            React.createElement("small", { className: 'text-danger mt-3 ms-1' },
                " ",
                error.message)));
};
export default UploadFile;
