import React from "react";
import { createRoot } from 'react-dom/client';
import RoutingApp from "./routers/RoutingApp";
import enableDebug from "./lib/enableDebug";
import store from './redux/store';
import DataProvider from "./components/DataProvider";
import { Provider } from 'react-redux';
import url from "./constants/url";
enableDebug();
/**
 * Main app for config tool
 *
 * @returns {*}
 * @constructor
 */
var App = function () {
    return React.createElement(DataProvider, { endpoint: url.APP_STATE, render: function () { return React.createElement(RoutingApp, null); } });
};
var container = document.getElementById('app');
if (container) {
    var root = createRoot(container);
    root.render(React.createElement(Provider, { store: store },
        React.createElement(App, null)));
}
