/**
 *Created by Mikael Lindahl on 2022-03-29
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useContext, useRef } from 'react';
import useDebug from "../../hooks/useDebug";
import { CanvasContext } from "./Annotate";
import useUpdateBox from "../../hooks/useUpdateBox";
import Utils from "../../lib/utils";
var debug = useDebug('components:annotate:Resize');
export default function Resizer(props) {
    var radius = 4;
    var style = {
        userSelect: 'none',
        position: "absolute",
        zIndex: 2
    };
    var color = 'DeepSkyBlue';
    var cursor;
    switch (props.position) {
        case 'upper-left':
            style.top = String(-radius);
            style.left = String(-radius);
            cursor = 'nw-resize';
            break;
        case 'upper-right':
            style.top = String(-radius);
            style.right = String(-radius);
            cursor = 'ne-resize';
            break;
        case 'bottom-left':
            style.bottom = String(-radius);
            style.left = String(-radius);
            cursor = 'sw-resize';
            break;
        case 'bottom-right':
            style.bottom = String(-radius);
            style.right = String(-radius);
            cursor = 'se-resize';
    }
    var canvasRef = useContext(CanvasContext);
    var onUpdateBox = useUpdateBox(props.pdfId, props.pageIndex);
    // Store startDragPos in a `ref` so handlers always have the latest value.
    var startDragPos = useRef({ x0: 0, y0: 0 });
    // Put box in a ref to expose changes to box to handleParentMouseMove
    var originalBoxRef = useRef(props.box);
    // Ref needed to handleMouseUp kan see props update of box
    var boxRef = useRef(props.box);
    boxRef.current = props.box;
    //
    // `useCallback` is needed because `removeEventListener`` requires the handler
    // to be the same as `addEventListener`.  Without `useCallback` React will
    // create a new handler each render.
    var handleParentMouseMove = useCallback(function (e) {
        var delta_x = e.clientX - startDragPos.current.x0;
        var delta_y = -(e.clientY - startDragPos.current.y0);
        var box;
        switch (props.position) {
            case 'upper-left':
                box = {
                    x0: originalBoxRef.current.x0 + delta_x,
                    y0: originalBoxRef.current.y0,
                    width: originalBoxRef.current.width - delta_x,
                    height: originalBoxRef.current.height + delta_y
                };
                break;
            case 'upper-right':
                box = {
                    x0: originalBoxRef.current.x0,
                    y0: originalBoxRef.current.y0,
                    width: originalBoxRef.current.width + delta_x,
                    height: originalBoxRef.current.height + delta_y
                };
                break;
            case 'bottom-left':
                box = {
                    x0: originalBoxRef.current.x0 + delta_x,
                    y0: originalBoxRef.current.y0 + delta_y,
                    width: originalBoxRef.current.width - delta_x,
                    height: originalBoxRef.current.height - delta_y
                };
                break;
            case 'bottom-right':
                box = {
                    x0: originalBoxRef.current.x0,
                    y0: originalBoxRef.current.y0 + delta_y,
                    width: originalBoxRef.current.width + delta_x,
                    height: originalBoxRef.current.height - delta_y
                };
                break;
        }
        var box_rest = Utils.getAlternativeBoxCoordinates(box.x0, box.y0, box.width, box.height, props.pageHeight);
        props.setBox(__assign(__assign(__assign({}, props.box), box), box_rest));
    }, []);
    var handleMouseDown = function (e) {
        props.setChanging(true);
        originalBoxRef.current = props.box;
        startDragPos.current = { x0: e.clientX, y0: e.clientY };
        canvasRef.current.addEventListener('mousemove', handleParentMouseMove);
        canvasRef.current.addEventListener('mouseup', handleMouseUp);
    };
    var handleMouseUp = useCallback(function (e) {
        onUpdateBox(boxRef.current, props.setBox);
        props.setChanging(false);
        originalBoxRef.current = props.box;
        startDragPos.current = { x0: 0, y0: 0 };
        canvasRef.current.removeEventListener('mousemove', handleParentMouseMove);
        canvasRef.current.removeEventListener('mouseup', handleMouseUp);
    }, []);
    return React.createElement("svg", { style: style, width: (radius + 1) * 2, height: (radius + 1) * 2, onMouseDown: handleMouseDown, cursor: cursor, onClick: function (e) {
            debug('stopPropagation');
            e.stopPropagation();
        } },
        React.createElement("circle", { cx: radius + 1, cy: radius + 1, r: radius, fill: color }));
}
