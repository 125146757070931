/**
 *Created by Mikael Lindahl on 2022-04-28
 */
"use strict";
/**
 *Created by Mikael Lindahl on 2022-04-28
 */
import { useState } from 'react';
var useFormUpload = function (props) {
    var _a = useState(null), formData = _a[0], setFormData = _a[1];
    var headers = props.headers || {};
    var onFormUpload = function (id) {
        console.log(formData, id);
        var url = id ? "".concat(props.path, "/").concat(id) : props.path;
        console.log(formData, id, url);
        fetch(url, {
            method: 'POST',
            headers: headers,
            body: formData,
        })
            .then(function (response) { return response.json(); })
            .then(function (result) {
            if (result.error) {
                throw result;
            }
            props.onSuccess(result);
            console.log('Success:', result);
        })
            .catch(function (error) {
            props.onError(error);
            console.error('Error:', error);
        });
    };
    return { formData: formData, onFormUpload: onFormUpload, setFormData: setFormData };
};
export default useFormUpload;
