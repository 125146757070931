/**
 *Created by Mikael Lindahl on 2019-10-08
 */
"use strict";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import url from "../constants/url";
import Home from "../pages/Home";
import Edit from "../pages/Edit";
import EditReview from "../pages/EditReview";
var debug = require('debug')('bk-manager:frontend-react:routers:RoutingApp');
/**
 * Main routing for the app
 *
 */
export default function RoutingApp() {
    return (React.createElement(Router, null,
        React.createElement(Routes, null,
            React.createElement(Route, { path: "".concat(url.APP_BASE, "/app"), element: React.createElement(Home, null) }),
            React.createElement(Route, { path: "".concat(url.APP_BASE, "/app/edit/:pdfId"), element: React.createElement(Edit, null) }),
            React.createElement(Route, { path: "".concat(url.APP_BASE, "/app/edit/review/:pdfId/:pageIndex"), element: React.createElement(EditReview, null) }))));
}
